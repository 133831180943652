<template>
  <div class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center">
    <div class="vx-col sm:w-4/5 md:w-1/2 lg:w-2/5 xl:w-1/4 sm:m-0 m-4">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">

          <div class="vx-row no-gutter justify-center items-center">
            <div class="vx-col xs:w-full sm:w-full md:w-full lg:w-full d-theme-dark-bg">
              <div class="p-8 login-tabs-container">
                <div class="vx-card__title mb-4">
                  <h4 class="mb-4">Setup Two-Factor Authentication</h4>
                </div>
                  
                <div class="vx-col w-full mb-4">
                  <p>Your company requires you to setup two-factor authentication. Use Google Authenticator or any authenticator app to scan this:</p>
                </div>
                <img style="margin:auto" :src="twoFactorDataUrl" />
                <div class="vx-col w-full flex justify-center">
                  <p class="mt-5">Or enter the key manually: </p>
                </div>
                <div class="vx-col w-full flex justify-center">
                  <p>{{formattedTwoFactorDataKey}}</p>
                </div>
                <div class="vx-col w-full mt-3">
                    <vs-input 
                      name="validationcode"
                      type="number"
                      label-placeholder="Enter verfication code"
                      class="w-full mt-5" placeholder="Verification code" 
                      v-model="twoFactorInputCode" 
                    />
                </div>
                <div v-if='showInvalidCodeMsg' class="vx-col w-full mt-2">
                    <span class="text-danger text-sm">Invalid verification code.</span>
                </div>

                <vs-button @click="handleCancel" color="danger" type="flat" class="ml-auto mt-6">Cancel</vs-button>
                <vs-button @click="handleSubmit" class="float-right ml-auto mt-6">Submit</vs-button>


              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
   

  </div>
</template>

<script>
import qrcode from  'qrcode'
import TpNotificationMixin from '@/components/travio-pro/TpNotificationMixin.vue'

export default {
  mixins: [TpNotificationMixin],
  data () {
    return {
      twoFactorInputCode: '',
      showInvalidCodeMsg: false,
      twoFactorSecret: '',
      twoFactorDataUrl: '',
      twoFactorDataKey: '',
      backupCodePrompt: false,
      backupCodes: [],
    }
  },
  computed: {
    oneTimeToken () { 
      return this.$store.state.auth.oneTimeTwoFactorToken
    },
    formattedTwoFactorDataKey () {
      // Add spaces every 4 chars
      return [...this.twoFactorDataKey].map((d, i) => (i) % 4 == 0 ? ' ' + d : d).join('').trim()
    }
  },
  mounted () {
    // This view will only be available after successful password login
    if(!this.oneTimeToken) {
      this.$_notifyFailure('You must provide user name and password first.')
      this.$router.push({name: 'page-login'})
    }
    this.generateTwoFactorCode()
  },
  methods: {
    generateTwoFactorCode () {
      this.$vs.loading();
      this.$http.post('api/auth/twofactor/authenticatorKey', { oneTimeToken: this.oneTimeToken })
        .then(response => {
          this.twoFactorDataUrl = response.data.dataUrl
          this.twoFactorDataKey = response.data.dataKey
          this.twoFactorSecret = response.data.secret
        })
        .catch(error => this.$_notifyFailure(error.response.data.title))
        .finally(() => this.$vs.loading.close())
    },
    handleSubmit () {
      this.$vs.loading();
      // If auth code is verified this will enable TwoFactorHasApp in the user table, and will save the secret as credential
      this.$http.post('api/auth/twofactor/setup', { 
        oneTimeToken: this.oneTimeToken,
        authCode: this.twoFactorInputCode,
        secret: this.twoFactorSecret
      })
      .then(response => {
        this.showInvalidCodeMsg = false;
        this.$store.dispatch('auth/persistTokens', {
          token: response.data.accessToken,
          refreshToken: response.data.refreshToken,
          userPermissions: response.data.userPermissions
        })
        
        this.$_notifySuccess('Two factor app has been enabled.')
        const startPageUrl = response.data.startPageUrl && `/${response.data.startPageUrl}`;
        this.$router.push(startPageUrl || '/');
        // this.$router.push(thisroute.query.redirect || response.data.startPageUrl || '/')
      })
      .catch(error => {
        this.showInvalidCodeMsg = true;
        this.$_notifyFailureByResponseData(error.response.data)
      })
      .finally(() => this.$vs.loading.close())     
    },
    handleCancel () {
      this.$store.dispatch('auth/removeOneTimeTwofactorToken')
      this.$router.push({name: 'page-login'});
    }
  }
}
</script>

<style>

</style>